<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>用户中心</el-breadcrumb-item>
      <el-breadcrumb-item>修改密码</el-breadcrumb-item>
    </el-breadcrumb>
    <el-form :model="ruleForm"
             :rules="rules"
             ref="ruleForm"
             label-width="100px"
             class="ruleForm"
             label-position="left">
      <!-- <el-form-item label="用户名">
        <el-input v-model="username"
                  readonly></el-input>
      </el-form-item>
      <el-form-item label="手机号码">
        <el-input v-model="telphone"
                  readonly></el-input>
      </el-form-item> -->
      <el-form-item label="新密码"
                    prop="pwd">
        <el-input type="password"
                  v-model="ruleForm.pwd"
                  clearable></el-input>
      </el-form-item>
      <el-form-item label="再次确认"
                    prop="checkPwd">
        <el-input type="password"
                  v-model="ruleForm.checkPwd"
                  clearable></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary"
                   @click="submitForm('ruleForm')"
                   :loading="loading"
                   round>确认修改</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import md5 from 'js-md5';
import { passwordValidate } from '../../utils/util'
import { edit_pwd,get_zifuchuan } from '@/api/user'
export default {
  name: 'changePsw',
  components: {
  },
  data () {
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.ruleForm.pwd) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    return {
      // username: '',
      // telphone: '',
      userid: '',
      ruleForm: {
        pwd: '',
        checkPwd: ''
      },
      rules: {
        pwd: passwordValidate,
        checkPwd: [
          { required: true, message: '请输入您的密码', trigger: 'blur' },
          { validator: validatePass2, trigger: 'blur' }
        ],
      },
      loading: false,
      character:0
    };
  },
  created () {
    let { username, telphone, id } = this.$store.state.user.stu_user_info[0]
    // this.username = username;
    // this.telphone = telphone;
    this.userid = id
    this.getzifuchuan()
  },
  methods: {
    //获取随机数
    async getzifuchuan () {
      let res = await get_zifuchuan({length:20});
      if (res.flag == '1') {
        var character = res.datainfo.character;
        this.character =character;
      } else if(res.flag =='-1'){
        this.$store.dispatch("logout",{
          tips: '登录失效,请重新登录'
        });
      } else {
        this.$alert(res.msg, '提示', {
          confirmButtonText: '确定',
          showClose: false,
          type: 'error',
        });
      }
    },
    /**提交 */
    submitForm (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.subfn();
        }
      });
    },
    async subfn () {
      let prames = {
        userid: this.userid,
        pwd: md5(this.ruleForm.pwd),
        character:this.character
      }
      this.loading = true;
      let res = await edit_pwd(prames)
      if (res.flag == '1') {
        this.$message({
          message: '修改成功，请登录',
          type: 'success'
        });
        this.$store.dispatch("logout")
        this.loading = false
      }else if(res.flag =='-1'){
        this.$store.dispatch("logout",{
          tips: '登录失效,请重新登录'
        });
      } else {
        this.$alert(res.msg, '提示', {
          confirmButtonText: '确定',
          type: 'error',
        });
        this.loading = false
      }
    }
  }
}
</script>
 <style lang="scss" scoped>
.ruleForm {
  margin-top: 40px;
  width: 50%;
}
</style>
